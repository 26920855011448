import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ChallengeList from '../../components/ChallengeList/ChallengeList';
import RankingTable from '../../components/RankingTable/RankingTable';
import './Home.css';
import PlayerSearch from '../../components/PlayerSearch/PlayerSearch';
import { useAuth } from '../../context/AuthContext';
import { allRankTypes, RankType } from "../../types/RankType";
import DynamicRankingTable from '../../components/DynamicRankingTable/DynamicRankingTable';
import { getDates } from '../../helpers/timeHelpers';
import OpenChallengeList from '../../components/OpenChallengeList/OpenChallengeList';
import { isVacationModeActive } from '../../helpers/vacationModeHelper';

const Home: React.FC = () => {
    const { nextYear, tomorrow, today, lastYear, yesterday, today2am, tomorrow2, threeDaysAgo } = getDates();
    const { playerAuth } = useAuth();
    const navigate = useNavigate();
    //const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const [shouldRenderPendingChallenges, setShouldRenderPendingChallenges] = useState<boolean>(true);
    const [shouldRenderLoadPendingChallenges, setShouldRenderLoadPendingChallenges] = useState<boolean>(true);
    const [shouldRenderMyNextChallenges, setShouldRenderMyNextChallenges] = useState<boolean>(true);
    const [shouldRenderGeneralNextChallenges, setShouldRenderGeneralNextChallenges] = useState<boolean>(true);
    const [shouldRenderPlayedChallenges, setShouldRenderPlayedChallenges] = useState<boolean>(true);
    const [playerRankTypes, setPlayerRankTypes] = useState<RankType[]>([]);
    const [shouldRenderOpenChallenges, setShouldRenderOpenChallenges] = useState<boolean>(true);
    const [vacationModeActive, setVacationModeActive] = useState<boolean>(false);

    useEffect(() => {
        if (playerAuth) {
            let playerRankings: RankType[] = [];
            allRankTypes.forEach(rankType => {
                if (playerAuth !== null && playerAuth !== undefined && playerAuth[rankType] !== null && playerAuth[rankType] !== undefined) {
                    playerRankings.push(rankType);
                }
            });
            setPlayerRankTypes(playerRankings);

            isVacationModeActive(playerAuth)
                .then((isVacationModeActive) => {
                    setVacationModeActive(isVacationModeActive);
                })
                .catch((error) => {
                    console.error('Error fetching vacation mode:', error);
                });
        }
    }, [playerAuth]);

    const handleCreateOpenChallenge = () => {
        navigate('/create-open-challenge');
    };

    /* const handleCreateFreeChallenge = () => {
        navigate('/create-free-challenge');
    }; */

    return (
        <div className="p-4">
            <Helmet>
                <title>WhatsRankings</title>
                <meta
                    name="description"
                    content="WhatsRankings - Plataforma de Ranking y Desafíos de Tenis"
                />
                <meta property="og:title" content="WhatsRankings" />
                <meta
                    property="og:description"
                    content="Plataforma de Ranking y Desafíos de Tenis"
                />
                <meta property="og:image" content="/logo1.png" />
            </Helmet>
            {/* Green Banner for Vacation Mode ON */}
            {vacationModeActive && (
                <div className="vacation-mode-banner">
                    <p className="vacation-mode-text">Modo Vacaciones activado</p>
                </div>
            )}

            <div className="logo-container">
                <img src="/logo1.png" alt="Whats Icon" />
            </div>

            {/* Desafios Pendiente de cargar */}
            {playerAuth && shouldRenderLoadPendingChallenges && (
                <>
                    <h1 className="section-title">Pendientes de Carga</h1>
                    <div className="player-challenges">
                        <ChallengeList
                            status={"accepted"}
                            from={lastYear}
                            to={today}
                            playerId={playerAuth.id}
                            setShouldRender={setShouldRenderLoadPendingChallenges}
                            orderDirection={'asc'}
                        />
                    </div>
                </>
            )}

            {/* Desafios pendientes de respuesta */}
            {playerAuth && playerAuth.id && shouldRenderPendingChallenges && (
                <section>
                    <h1 className="section-title">Pendientes de respuesta</h1>
                    <ChallengeList
                        status="pending"
                        from={yesterday}
                        to={nextYear}
                        playerId={playerAuth.id}
                        setShouldRender={setShouldRenderPendingChallenges}
                        orderDirection={'asc'}
                    />
                </section>
            )}

            {/* Fin de semana de Desafíos libres - 18/19 Enero */}
            {/* <section className="open-challenge-section highlight-section-event">
                <h1 className="section-title">Fin de semana de Desafíos libres</h1>
                {!vacationModeActive && (
                    <div>
                        <p className="section-description">
                            El <strong>18 y 19 de Enero</strong> podrás crear desafíos libres para jugar con cualquier jugador del ranking. ¡Anímate!
                        </p>
                        <button className="create-free-challenge-button" onClick={handleCreateFreeChallenge}>
                            CREAR DESAFÍO LIBRE
                        </button>
                    </div>

                )}
            </section> */}

            <section className="open-challenge-section">
                <h1 className="section-title">Desafíos Abiertos</h1>
                {!vacationModeActive && (
                    <div>
                        <p className="section-description">
                            Los desafíos abiertos son desafíos que cualquier jugador en tu rango puede aceptar.
                        </p>
                        <button className="create-challenge-button" onClick={handleCreateOpenChallenge}>
                            CREAR DESAFÍO ABIERTO
                        </button>
                    </div>

                )}
            </section>



            {/* Proximos desafios ABIERTOS del usuario */}
            {playerAuth && shouldRenderOpenChallenges && (
                <section>
                    <h1 className="section-title">Desafíos Abiertos en mi rango</h1>
                    <OpenChallengeList
                        playerId={playerAuth.id}
                        setShouldRender={setShouldRenderOpenChallenges}
                    />
                </section>
            )}


            {/* Proximos desafios del usuario */}
            {playerAuth && shouldRenderMyNextChallenges && (
                <section>
                    <h1 className="section-title">Mis Próximos desafíos</h1>
                    <ChallengeList
                        status="accepted"
                        from={today}
                        to={nextYear}
                        playerId={playerAuth.id}
                        setShouldRender={setShouldRenderMyNextChallenges}
                        orderDirection={'asc'}
                    />
                </section>
            )}

            {/* Rankings if a player is NOT logged in */}
            {/* {!playerAuth && (
                <>
                    <section>
                        <h1 className="section-title">Rankings</h1>
                        {allRankTypes.map(rankType => (
                            <RankingTable key={rankType} rankType={rankType} />
                        ))}
                    </section>
                </>
            )} */}

            {/* Rankings if a player is logged in */}
            {playerAuth && (
                <>
                    <section className="mb-8">
                        <h1 className="section-title">Rankings</h1>
                        {playerRankTypes.map(rankType => {
                            if (playerAuth && playerAuth[rankType] !== null && playerAuth[rankType] !== undefined) {
                                return <RankingTable key={rankType} rankType={rankType} />
                            }
                            return null;
                        })}
                    </section>
                </>
            )}
            <div className='table-leyend-container'>
                <div className='table-leyend-item'>
                    <div className='leyend-item-image'>
                        <img src="/icons/inactive.png" alt="Inactive Icon" />
                    </div>
                    <p className='leyend-item-text'>Jugador Inactivo</p>
                </div>

                <div className='table-leyend-item'>
                    <div className='leyend-item-image'>
                        <img src="/icons/vacation.png" alt="Vacation Icon" />
                    </div>
                    <p className='leyend-item-text'>Jugador en Vacaciones</p>
                </div>
            </div>

            {/* Buscador de jugadores */}
            <section>
                <h1 className="section-title">Buscador</h1>
                <PlayerSearch />
            </section>

            {/* Desafios del dia generales */}
            {shouldRenderGeneralNextChallenges && (
                <section>
                    <h1 className="section-title">Desafios del día</h1>
                    <ChallengeList
                        status="accepted"
                        from={today2am}
                        to={tomorrow2}
                        setShouldRender={setShouldRenderGeneralNextChallenges}
                        orderDirection='asc' />
                </section>
            )}

            {/* Ranking de nivel */}
            {playerAuth && (
                <section>
                    <h1 className="section-title">En tu rango de Nivel ELO</h1>
                    <DynamicRankingTable />
                </section>
            )}

            {/* Ultimos Resultados */}
            {shouldRenderPlayedChallenges && (
                <section>
                    <h1 className="section-title">Últimos resultados</h1>
                    <div className="challenge-list">
                        <ChallengeList status="played" from={threeDaysAgo} to={tomorrow} setShouldRender={setShouldRenderPlayedChallenges} orderDirection={'desc'} />
                    </div>
                </section>
            )}
        </div>
    );
};

export default Home;
