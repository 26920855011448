import React, { useEffect, useState } from 'react';
import { getRankingPermutationsByPlayerId } from '../../services/RankingPermutationService';
import { useParams } from 'react-router-dom';
import './LevelRankingChart.css';
import { RankingPermutation } from '../../types/RankingPermution';
import { LineChart } from '@mui/x-charts';
import { useMediaQuery } from 'react-responsive';

const LevelRankingChart: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [levelDataset, setLevelDataset] = useState<any[]>([]);
  const [levelLabels, setLevelLabels] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  /* const isValidDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };
 */
  // Función para filtrar solo el último cambio de cada día
  const filterLatestByDay = (changes: RankingPermutation[]) => {
    const latestByDay: Record<string, RankingPermutation> = {};

    changes.forEach(change => {
      const day = new Date(change.createdAt).toISOString().split('T')[0]; // Extraer solo la fecha (AAAA-MM-DD)
      if (!latestByDay[day] || new Date(change.createdAt).getTime() > new Date(latestByDay[day].createdAt).getTime()) {
        latestByDay[day] = change; // Reemplazar si es más reciente
      }
    });

    return Object.values(latestByDay);
  };

  useEffect(() => {
    const fetchRankingChanges = async () => {
      try {
        if (id) {
          const today = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(today.getMonth() - 6);
  
          // La fecha debe ser hace seis meses o el 13 de octubre de 2024, lo que ocurra primero
          const firstDate = new Date(2024, 9, 1);
          const dateToUse = sixMonthsAgo < firstDate ? firstDate : sixMonthsAgo;
  
          const changes: RankingPermutation[] = await getRankingPermutationsByPlayerId(parseInt(id), dateToUse, today);
          // Ordenar cambios por `createdAt`
          changes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  
          // Filtrar por tipo de ranking dinámico
          const levelChanges = changes.filter(change => change.rankingType === 'dynamicLevel');
  
          // Filtrar para mantener solo el último cambio de cada día
          const filteredLevelChanges = filterLatestByDay(levelChanges);
  
          // Preparar etiquetas y valores para el gráfico
          const levelDates: string[] = [];
          const levelValues: number[] = [];
  
          if (filteredLevelChanges.length > 0) {
            levelDates.push(dateToUse.toLocaleDateString());
            levelValues.push(filteredLevelChanges[0].previousDynamicRanking); // Valor inicial
  
            filteredLevelChanges.forEach(change => {
              levelDates.push(new Date(change.createdAt).toLocaleDateString());
              levelValues.push(parseFloat(change.newDynamicRanking.toFixed(2)));
            });
          }
  
          setLevelDataset(levelValues);
          setLevelLabels(levelDates);
        }
      } catch (err) {
        setError('Error al obtener los cambios de ranking');
      } finally {
        setLoading(false);
      }
    };
  
    fetchRankingChanges();
  }, [id]);

  if (loading) return <p>Cargando gráfico...</p>;
  if (error) return <p>{error}</p>;
  if (levelDataset.length === 0) return <></>;

  return (
    <div className="player-chart-rankings-container">
      <h2 className="heading-secondary">Nivel en los últimos 6 meses</h2>
      {/* //Render charts only if there is data */}
      {levelDataset.length > 0 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: levelDataset, label: 'Nivel', color: 'red' },
            ]}
            xAxis={[{
              scaleType: 'point',
              data: levelLabels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17 },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15 },
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 0.2,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17 },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15 },
            }]}
          />
        </div>
      }
    </div>
  );
};

export default LevelRankingChart;
