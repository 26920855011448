import React, { useState } from 'react';
import { recoverPassword } from '../../services/AuthService'; // Create a recoverPassword service
import './RecoverPassword.css'; // Add styles for the form

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      await recoverPassword(email); // Assume recoverPassword sends a recovery email
      setLoading(false);
      setMessage('Email de recuperacion enviado. Por favor revisa tu bandeja de entrada.');
      setError(null);
    } catch (err) {
        console.log(err);
      setError('Failed to send recovery email. Please try again.');
      setMessage(null);
    }
  };

  return (
    <div className="recover-container">
      <h2 className="recover-title">Recuperar Contraseña</h2>
      <form onSubmit={handleSubmit} className="recover-form">
        {/* Loading */}
        {loading && <div className="loading">Enviando...</div>}
        {/* Email */}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {message && <div className="success-message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
        <button type="submit" className="recover-button">Enviar email recuperación</button>
      </form>
    </div>
  );
};

export default RecoverPassword;
