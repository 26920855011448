import React, { useEffect, useState } from 'react';
import { getRankingPermutationsByPlayerId } from '../../services/RankingPermutationService';
import { useParams } from 'react-router-dom';
import './RankingChart.css';
import { RankingPermutation } from '../../types/RankingPermution';
import { LineChart } from '@mui/x-charts';
import { useMediaQuery } from 'react-responsive';

const RankingChart: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [absoluteDataset, setAbsoluteDataset] = useState<number[]>([]);
  const [absoluteLabels, setAbsoluteLabels] = useState<string[]>([]);
  const [plus45Dataset, setPlus45Dataset] = useState<any[]>([]);
  const [plus45Labels, setPlus45Labels] = useState<string[]>([]);
  const [plus55Dataset, setPlus55Dataset] = useState<any[]>([]);
  const [plus55Labels, setPlus55Labels] = useState<string[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  /* const isValidDate = (dateString: string | Date) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }; */

  const processChanges = (filteredChanges: RankingPermutation[], dateToUse: Date) => {
    const labels: string[] = [];
    const values: number[] = [];

    if (filteredChanges.length > 0) {
      labels.push(dateToUse.toLocaleDateString());
      values.push(filteredChanges[0].previousRank); // Valor inicial

      filteredChanges.forEach(change => {
        labels.push(new Date(change.createdAt).toLocaleDateString());
        values.push(change.newRank);
      });
    }

    return { labels, values };
  };

  useEffect(() => {
    const fetchRankingChanges = async () => {
      try {
        if (id) {
          const today = new Date();
          const sixMonthsAgo = new Date();
          sixMonthsAgo.setMonth(today.getMonth() - 6);
          const firstDate = new Date(2024, 9, 1);
          const dateToUse = sixMonthsAgo < firstDate ? firstDate : sixMonthsAgo;
    
          const changes: RankingPermutation[] = await getRankingPermutationsByPlayerId(parseInt(id), dateToUse, today);
          changes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    
          const absolutoChanges = filterLatestByDay(changes.filter(change => change.rankingType === 'absoluteOrdinalRanking'));
          const plus45Changes = filterLatestByDay(changes.filter(change => change.rankingType === 'age45OrdinalRanking'));
          const plus55Changes = filterLatestByDay(changes.filter(change => change.rankingType === 'age55OrdinalRanking'));
    
          const { labels: absolutoLabels, values: absolutoValues } = processChanges(absolutoChanges, dateToUse);
          const { labels: plus45Labels, values: plus45Values } = processChanges(plus45Changes, dateToUse);
          const { labels: plus55Labels, values: plus55Values } = processChanges(plus55Changes, dateToUse);
    
          setAbsoluteLabels(absolutoLabels);
          setAbsoluteDataset(absolutoValues);
          setPlus45Labels(plus45Labels);
          setPlus45Dataset(plus45Values);
          setPlus55Labels(plus55Labels);
          setPlus55Dataset(plus55Values);
        }
      } catch (err) {
        setError('Error al obtener los cambios de ranking');
      } finally {
        setLoading(false);
      }
    };

    fetchRankingChanges();
  }, [id]);

  const filterLatestByDay = (changes: RankingPermutation[]) => {
    const latestByDay: Record<string, RankingPermutation> = {};
  
    changes.forEach(change => {
      const day = new Date(change.createdAt).toISOString().split('T')[0]; // Extraer solo la fecha (AAAA-MM-DD)
      if (!latestByDay[day] || new Date(change.createdAt).getTime() > new Date(latestByDay[day].createdAt).getTime()) {
        latestByDay[day] = change; // Reemplazar si es más reciente
      }
    });
  
    return Object.values(latestByDay);
  };

  if (loading) return <p>Cargando gráfico...</p>;
  if (error) return <p>{error}</p>;
  if (absoluteDataset.length === 0 && plus45Dataset.length === 0 && plus55Dataset.length === 0) return <></>;

  return (
    <div className="player-chart-rankings-container">
      <h2 className="heading-secondary">Ranking en los últimos 6 meses</h2>
      {/* //Render charts only if there is data */}
      {absoluteDataset.length > 1 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: absoluteDataset, label: 'Absoluto' }
            ]}
            xAxis={[{
              scaleType: 'point',
              data: absoluteLabels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17 },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15 },
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 1,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17 },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              reverse: true
            }]}
          />
        </div>
      }

      {plus45Dataset.length > 1 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: plus45Dataset, label: '+45' }
            ]}
            xAxis={[{
              scaleType: 'point',
              data: plus45Labels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 1,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              reverse: true
            }]}
          />
        </div>
      }

      {plus55Dataset.length > 1 &&
        <div className="chart-container">
          <LineChart
            width={isMobile ? 360 : 600}
            height={isMobile ? 300 : 380}
            series={[
              { data: plus55Dataset, label: '+55' }
            ]}
            xAxis={[{
              scaleType: 'point',
              data: plus55Labels,
              label: 'Fecha',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              tickSize: 6,
            }]}
            yAxis={[{
              scaleType: 'linear',
              position: 'left',
              tickSize: 6,
              tickNumber: 4,
              tickMinStep: 1,
              label: 'Ranking',
              labelStyle: { color: 'black', fontSize: isMobile ? 15 : 17  },
              tickLabelStyle: { color: 'black', fontSize: isMobile ? 12 : 15},
              reverse: true
            }]}
          />
        </div>
      }
    </div>
  );
};

export default RankingChart;
