import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getChallengeCountByStatus } from '../../services/ChallengeService';
import { getInactivePlayersCount, getTotalPlayersCount } from '../../services/PlayerService';
import { getOpenChallengesCount } from '../../services/OpenChallengeService';
import { getMatchesCountWithoutConfirmation } from '../../services/MatchService';
import { Helmet } from 'react-helmet';

const Admin: React.FC = () => {
    const [acceptedChallenges, setAcceptedChallenges] = useState<number>();
    const [pendingChallenges, setPendingChallenges] = useState<number>();
    const [playedChallenges, setPlayedChallenges] = useState<number>();
    const [inactivePlayers, setInactivePlayers] = useState<number>();
    const [totalPlayers, setTotalPlayers] = useState<number>();
    const [openChallengesCount, setOpenChallengesCount] = useState<number>();
    const [challengesWithoutConfirmation, setChallengesWithoutConfirmation] = useState<number>(0);

    useEffect(() => {
        //Get all challenge counts by calling getChallengeCountByStatus
        getChallengeCountByStatus('accepted').then((count) => setAcceptedChallenges(count));
        getChallengeCountByStatus('pending').then((count) => setPendingChallenges(count));
        getChallengeCountByStatus('played').then((count) => setPlayedChallenges(count));
        getInactivePlayersCount().then((count) => setInactivePlayers(count));
        getTotalPlayersCount().then((count) => setTotalPlayers(count));
        getOpenChallengesCount().then((count) => setOpenChallengesCount(count));
        getMatchesCountWithoutConfirmation().then((count) => setChallengesWithoutConfirmation(count));
    }, []);

    return (
        <div className="admin-container">
            <Helmet>
                <title>Panel de Administración - WhatsRankings</title>
            </Helmet>
            <h1 className="text-2xl font-bold mb-4">Panel de Administración</h1>
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Desafios actuales</h2>
                <div className="grid grid-cols-3 gap-6">
                    <div className="bg-gray-200 p-4 rounded">
                        <h3 className="text-md font-semibold">Desafíos Pendientes</h3>
                        <p className="text-2xl font-bold">{pendingChallenges}</p>
                    </div>
                    <div className="bg-gray-200 p-4 rounded">
                        <h3 className="text-md font-semibold">Desafíos Aceptados</h3>
                        <p className="text-2xl font-bold">{acceptedChallenges}</p>
                    </div>
                    <div className="bg-gray-200 p-4 rounded">
                        <h3 className="text-md font-semibold">Desafíos Jugados</h3>
                        <p className="text-2xl font-bold">{playedChallenges}</p>
                    </div>
                    {/* Inactive players */}
                    <div className="bg-gray-200 p-4 rounded">
                        <h3 className="text-md font-semibold">Jugadores Inactivos</h3>
                        <p className="text-2xl font-bold">{inactivePlayers}/{totalPlayers}</p>
                    </div>
                    {/* Open challenges */}
                    <div className="bg-gray-200 p-4 rounded">
                        <h3 className="text-md font-semibold">Desafíos Abiertos</h3>
                        <p className="text-2xl font-bold">{openChallengesCount}</p>
                    </div>
                    {/* Challenges without confirmation */}
                    <div className="bg-gray-200 p-4 rounded">
                        <h3 className="text-md font-semibold">Partidos sin confirmar</h3>
                        <p className="text-2xl font-bold">{challengesWithoutConfirmation}</p>
                    </div>
                </div>
            </div>
            <ul className="space-y-2">
                <h2 className="text-xl font-semibold mb-2">Acciones</h2>
                <li>
                    <Link
                        to="/tennisadmin/challenges"
                        className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Ver Desafíos
                    </Link>
                </li>
                <li>
                    <Link
                        to="/tennisadmin/matches"
                        className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Ver Partidos a confirmar
                    </Link>
                </li>
                <li>
                    <Link
                        to="/tennisadmin/challenge-form"
                        className="inline-block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Crear Desafío
                    </Link>
                </li>
                <li>
                    <a
                        href="https://tenis-app-backend.onrender.com/api/players/download?rankType=absoluteranking"
                        className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Ranking Absoluto
                    </a>
                </li>
                <li>
                    <a
                        href="https://tenis-app-backend.onrender.com/api/players/download?rankType=age45ranking"
                        className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Ranking +45
                    </a>
                </li>
                <li>
                    <a
                        href="https://tenis-app-backend.onrender.com/api/players/download/?rankType=age55ranking"
                        className="inline-block px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Ranking +55
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Admin;
