import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import PlayerDetails from '../components/PlayerDetails/PlayerDetails';
import Challenges from '../pages/Challenges/Challenges';
import NotFound from '../components/NotFound';
import Home from '../pages/Home/Home';
import Rankings from '../pages/Rankings/Rankings';
import Login from '../components/Login/Login';
import ChallengeForm from '../components/ChallengeForm/ChallengeForm';
import Logout from '../components/Logout/Logout';
import ChallengeDetails from '../components/ChallengeDetails/ChallengeDetails';
import ResultForm from '../components/ResultForm/ResultForm';
import AdminChallengeView from '../components/AdminChallengeView/AdminChallengeView';
import AdminMatchView from '../components/AdminMatchView/AdminMatchView';
import AdminChallengeForm from '../components/AdminChallengeForm/AdminChallengeForm';
import Admin from '../pages/Admin/Admin';
import OpenChallengeForm from '../components/OpenChallengeForm/OpenChallengeForm';
import RecoverPassword from '../components/RecoverPassword/RecoverPassword';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <main className="container mx-auto p-4">
        <Routes>
          <Route path="/" element={<Home />} /> {/* Home route */}
          <Route path="/players/:id" element={<PlayerDetails />} />
          <Route path="/challenges" element={<Challenges />} />
          <Route path="/rankings" element={<Rankings />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/create-challenge" element={<ChallengeForm />} />
          <Route path="/create-open-challenge" element={<OpenChallengeForm />} />
          <Route path="/create-free-challenge" element={<ChallengeForm />} />
          <Route path="/challenges/:id" element={<ChallengeDetails />} />
          <Route path="/load-result" element={<ResultForm />} />
          <Route path="/tennisadmin" element={<Admin />} /> 
          <Route path="/tennisadmin/challenges" element={<AdminChallengeView />} />
          <Route path="/tennisadmin/matches" element={<AdminMatchView />} />
          <Route path="/tennisadmin/challenge-form" element={<AdminChallengeForm />} />
          <Route path="/recover-password" element={<RecoverPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
