import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { canBeChallenged, getPlayerById, updatePlayer, activateVacationMode, deactivateVacationMode, getPlayerTotals } from '../../services/PlayerService';
import { Player } from '../../types/Player';
import { useAuth } from '../../context/AuthContext';
import './PlayerDetails.css';
import ChallengeList from '../ChallengeList/ChallengeList';
import MatchList from '../MatchList/MatchList';
import CreateChallengeButton from '../Buttons/CreateChallengeButton/CreateChallengeButton';
import { changePassword } from '../../services/UserService';
import { getDates } from '../../helpers/timeHelpers';
import RankingChart from '../RankingChart/RankingChart';
import { useMediaQuery } from 'react-responsive';
import LevelRankingChart from '../LevelRankingChart/LevelRankingChart';
import { MatchStatsView } from '../../types/MatchStatsView';
import { didPlayerHaveWorsePreRankInLastThreeMatches, getPlayerStatsByDateRange } from '../../services/MatchService';
import { PieChart } from '@mui/x-charts/PieChart';
import { isVacationModeActive } from '../../helpers/vacationModeHelper';

const PlayerDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [player, setPlayer] = useState<Player | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPasswordChange, setShowPasswordChange] = useState<boolean>(false);
  const { isAuthenticated, playerAuth, userAuth } = useAuth();
  const { nextYear, tomorrow, today, lastYear } = getDates();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [userCanBeChallenged, setUserCanBeChallenged] = useState(false);
  const [playerStats, setPlayerStats] = useState<MatchStatsView>();
  const [vacationMode, setVacationMode] = useState<boolean>(false);
  const [loggedInPlayervacationModeActive, setLoggedInPlayervacationModeActive] = useState<boolean>(false);
  const [playerVacationModeActive, setPlayerVacationModeActive] = useState<boolean>(false);
  const [vacationModeTabOpen, setVacationModeTabOpen] = useState<boolean>(false);
  const [vacationDays, setVacationDays] = useState<number | string>(0);
  const [vacationModeStartDateFormatted, setVacationModeStartDateFormatted] = useState<string | null>(null);
  const [vacationModeEndDateFormatted, setVacationModeEndDateFormatted] = useState<string | null>(null);
  const [playerHasDefendedRecently, setPlayerHasDefendedRecently] = useState<boolean>(false);
  const [playerTotals, setPlayerTotals] = useState<number | null>(null);

  // Hooks for navigation and media queries
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // Editable fields
  const [editablePhone, setEditablePhone] = useState<string | undefined>('');
  const [editableSocialNumber, setEditableSocialNumber] = useState<string | undefined>('');
  const [editablePreferredTimes, setEditablePreferredTimes] = useState<string | undefined>('');
  const [isEditingPhone, setIsEditingPhone] = useState<boolean>(false);
  const [isEditingSocialNumber, setIsEditingSocialNumber] = useState<boolean>(false);
  const [isEditingPreferredTimes, setIsEditingPreferredTimes] = useState<boolean>(false);

  const [shouldRenderPendingChallenges, setShouldRenderPendingChallenges] = useState<boolean>(true);
  const [shouldRenderLoadPendingChallenges, setShouldRenderLoadPendingChallenges] = useState<boolean>(true);
  const [shouldRenderMyNextChallenges, setShouldRenderMyNextChallenges] = useState<boolean>(true);
  const [shouldRenderPlayedChallenges, setShouldRenderPlayedChallenges] = useState<boolean>(true);

  const loadPlayerDetails = useCallback(async () => {
    try {
      if (id) {
        const playerData = await getPlayerById(parseInt(id));
        setPlayer(playerData);
        setEditablePhone(playerData.phone);
        setEditableSocialNumber(playerData.socialNumber);
        setEditablePreferredTimes(playerData.preferredTimes);
        setVacationMode(playerData.vacationMode);
        setVacationDays(playerData.vacationModeRequestedDays || 0);
        setVacationModeStartDateFormatted(
          playerData.vacationModeStartDate
            ? new Date(playerData.vacationModeStartDate).toLocaleDateString('es-ES')
            : null
        );
        setVacationModeEndDateFormatted(
          playerData.vacationModeEndDate
            ? new Date(playerData.vacationModeEndDate).toLocaleDateString('es-ES')
            : null
        );

        const playerHasDefended: boolean = await didPlayerHaveWorsePreRankInLastThreeMatches(playerData.id);
        setPlayerHasDefendedRecently(!playerHasDefended);

        //Checks vacation mode active of player:
        const vacationModeActive = await isVacationModeActive(playerData);
        setPlayerVacationModeActive(vacationModeActive);

        //Checks vacation mode active of logged in player:
        if (playerAuth) {
          const vacationModeActive = await isVacationModeActive(playerAuth);
          setLoggedInPlayervacationModeActive(vacationModeActive);
        }

        const fromDate = new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate());
        const today = new Date();
        const statsData = await getPlayerStatsByDateRange(playerData.id, fromDate.toISOString(), today.toISOString());
        setPlayerStats(statsData);
        /* Total challenges played. TO DO: put more stats*/
        const totals = await getPlayerTotals(playerData.id);
        setPlayerTotals(totals);
      }
    } catch (err) {
      setError('Error al obtener los detalles del jugador');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [id, playerAuth]);

  useEffect(() => {
    loadPlayerDetails();
  }, [id, error, loadPlayerDetails]);

  useEffect(() => {
    if (player) {
      window.scrollTo(0, 0);
    }
  }, [player]);

  useEffect(() => {
    if (playerAuth && player) {
      userChallengeable(playerAuth.id, player.id);
    }
  }, [playerAuth, player]);

  const userChallengeable = async (player1Id: number, player2Id: number) => {
    const challengeable = await canBeChallenged(player1Id, player2Id);
    setUserCanBeChallenged(challengeable);
  }

  const handleSavePhone = async () => {
    if (player && editablePhone !== undefined) {
      try {
        const updatedPlayer = await updatePlayer(player.id, { phone: editablePhone });
        setPlayer(updatedPlayer);
        setIsEditingPhone(false);
      } catch (err) {
        setError('Error al actualizar el teléfono');
      }
    }
  };

  const handleSaveSocialNumber = async () => {
    if (player && editableSocialNumber !== undefined) {
      try {
        const updatedPlayer = await updatePlayer(player.id, { socialNumber: editableSocialNumber });
        setPlayer(updatedPlayer);
        setIsEditingSocialNumber(false);
      } catch (err) {
        setError('Error al actualizar el número social');
      }
    }
  };

  const handleSavePreferredTimes = async () => {
    if (player && editablePreferredTimes !== undefined) {
      try {
        const updatedPlayer = await updatePlayer(player.id, { preferredTimes: editablePreferredTimes });
        setPlayer(updatedPlayer);
        setIsEditingPreferredTimes(false);
      } catch (err) {
        setError('Error al actualizar los horarios preferentes');
      }
    }
  }

  const handleSocialNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableSocialNumber(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditablePhone(event.target.value);
  };

  const handlePreferredTimesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditablePreferredTimes(event.target.value);
  }

  const handleChangePassword = async () => {
    if (newPassword && newPassword === confirmPassword) {
      try {
        if (!userAuth) throw new Error('Usuario no autenticado');
        if (currentPassword === '') {
          setError('Debes ingresar la contraseña actual');
          throw new Error('Ingrese la contraseña actual');
        }

        await changePassword(userAuth.id, currentPassword, newPassword);
        alert('Contraseña actualizada exitosamente');
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPassword('');
        setShowPasswordChange(false);
        navigate('/');
      } catch (err) {
        setError('Error al actualizar la contraseña');
      }
    } else {
      setError('Las contraseñas no coinciden');
    }
  };

  const handleToggleVacationMode = async () => {
    if (player) {
      if (vacationMode) {
        // Desactivar modo vacaciones
        try {
          //Confirm to the user
          const confirmDeactivateVacation = window.confirm('¿Estás seguro de que deseas desactivar el modo vacaciones?');
          if (!confirmDeactivateVacation) {
            return;
          }

          await deactivateVacationMode(player.id);
          loadPlayerDetails();
        } catch (err) {
          setError('Error al desactivar el modo vacaciones');
        }
      } else {
        // Activar modo vacaciones
        if (vacationDays === '' || Number(vacationDays) < 0 || Number(vacationDays) > 30) {
          setError('Por favor ingrese un número de días válido entre 0 y 30');
          return;
        }

        //Confirm to the user
        const confirmActivateVacation = window.confirm(`¿Estás seguro de que deseas activar el modo vacaciones por ${vacationDays} días?`);
        if (!confirmActivateVacation) {
          return;
        }

        try {
          await activateVacationMode(player.id, Number(vacationDays));
          loadPlayerDetails();
        } catch (err) {
          setError('Error al activar el modo vacaciones');
        } finally {
          setVacationModeTabOpen(false);
        }
      }
    }
  };

  const handleVacationDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVacationDays(e.target.value);
  };

  const togglePasswordChangeSection = () => {
    setShowPasswordChange(!showPasswordChange);
  };

  if (loading) return <p>Cargando...</p>;
  if (!player) return <p>No se encontró el jugador</p>;

  return (
    <div className="player-details-container">
      {/* Meta title */}
      <Helmet>
        <title>{`Detalles del Jugador: ${player.firstName} ${player.lastName}`}</title>
        <meta property="og:title" content={`Detalles del Jugador: ${player.firstName} ${player.lastName}`} />
        <meta
          property="og:description" content={`Detalles del Jugador: ${player.firstName} ${player.lastName}`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <h2 className="heading center">{player.firstName} {player.lastName}</h2>
      <div className="player-states-container">
        {playerVacationModeActive && (
          <div className='edit-phone-container'>
            <img src="/icons/vacation.png" alt="Vacation Icon" /> <span className='vacation-mode-status'>Jugador en Vacaciones</span>
          </div>
        )}
        {/* VacationMode off and Inactive player */}
        {!playerVacationModeActive && !player.active && (
          <div className='edit-phone-container'>
            <img src="/icons/inactive.png" alt="Inactive Icon" /> <span className='vacation-mode-status'>Jugador Inactivo</span>
          </div>
        )}
      </div>
      {isAuthenticated && playerAuth && playerAuth.id !== player.id && userCanBeChallenged && !loggedInPlayervacationModeActive && (
        <CreateChallengeButton player={player} isAuthenticated={isAuthenticated} />
      )}
      <div className="player-info">
        <div className="player-column telephone-column">
          <h3 className="subheading">Teléfono</h3>
          {isEditingPhone ? (
            <div className="editable-phone">
              <input
                type="text"
                value={editablePhone}
                onChange={handlePhoneChange}
                className="phone-input"
              />
              <button
                onClick={handleSavePhone}
                className="save-button"
                disabled={!editablePhone || editablePhone.trim() === ""}
              >
                Guardar
              </button>
            </div>
          ) : (
            <div className="phone-container">
              <p className="player-info-text text">{player.phone || '-'}</p>
              {isAuthenticated && playerAuth && playerAuth.id === player.id && (
                <div className='edit-phone-container'>
                  <img onClick={() => setIsEditingPhone(true)} src="/icons/edit.png" alt="Edit Icon" />
                </div>
              )}
            </div>
          )}
        </div>

        {/* Social Number Section */}
        <div className="player-column telephone-column">
          <h3 className="subheading">Número de socio</h3>
          {isEditingSocialNumber ? (
            <div className="editable-phone">
              <input
                type="text"
                value={editableSocialNumber}
                onChange={handleSocialNumberChange}
                className="phone-input"
              />
              <button
                onClick={handleSaveSocialNumber}
                className="save-button"
                disabled={!editableSocialNumber || editableSocialNumber.trim() === ""}
              >
                Guardar
              </button>
            </div>
          ) : (
            <div className="phone-container">
              <p className="player-info-text text">{player.socialNumber || '-'}</p>
              {isAuthenticated && playerAuth && playerAuth.id === player.id && (
                <div className='edit-phone-container'>
                  <img onClick={() => setIsEditingSocialNumber(true)} src="/icons/edit.png" alt="Edit Icon" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="player-info">
        {/* Preferred times Section */}
        <div className="player-column preferred-times-column">
          <h3 className="subheading">Preferencia de horarios</h3>
          {isEditingPreferredTimes ? (
            <div className="editable-phone">
              <input
                type="text"
                value={editablePreferredTimes}
                onChange={handlePreferredTimesChange}
                className="preferred-times-input"
              />
              <button
                onClick={handleSavePreferredTimes}
                className="save-button"
                disabled={!editablePreferredTimes || editablePreferredTimes.trim() === ""}
              >
                Guardar
              </button>
            </div>
          ) : (
            <div className="phone-container">
              <p className="preferred-times-p">{player.preferredTimes || '-'}</p>
              {isAuthenticated && playerAuth && playerAuth.id === player.id && (
                <div className='edit-phone-container'>
                  <img onClick={() => setIsEditingPreferredTimes(true)} src="/icons/edit.png" alt="Edit Icon" />
                </div>
              )}
            </div>
          )}
        </div>
        {/* Position recently defended */
          playerHasDefendedRecently ? (
            <div className="player-column position-defended-column">
              <div className='position-defended-container'>
                <img src="/icons/shield.png" alt="Shield Icon" />
                <span className='vacation-mode-status'>Posicion defendida</span>
              </div>
            </div>) :
            (
              <div className="player-column position-defended-column">
                <div className='position-defended-container'>
                  <img src="/icons/shield-variant.png" alt="Defend Icon" />
                  <span className='vacation-mode-status'>Debes defender tu posicion en tu próximo desafío</span>
                </div>
              </div>
            )
        }
      </div>
      {isAuthenticated && playerAuth && playerAuth.id === player.id && (
        <>
          <div className="change-password-container">
            <button onClick={togglePasswordChangeSection} className="change-password-button toggle-password">
              Cambiar contraseña
            </button>

            {showPasswordChange && (
              <>
                <input
                  type="password"
                  placeholder="Contraseña actual"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="password-input"
                />
                <input
                  type="password"
                  placeholder="Nueva contraseña"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="password-input"
                />
                <input
                  type="password"
                  placeholder="Confirmar nueva contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="password-input"
                />
                <button onClick={handleChangePassword} className="save-button save-password-button">
                  Guardar Contraseña
                </button>
              </>
            )}
          </div>
        </>
      )}

      {/* Modo Vacaciones */}
      {isAuthenticated && playerAuth && playerAuth.id === player.id && (
        <div>
          <h2 className="heading-secondary">Modo Vacaciones</h2>
          <div className='vacation-mode-section'>
            {/* Información de modo vacaciones */}
            <p className="vacation-mode-section-text">Tienes un total de 30 días al año para usar en Modo Vacaciones.</p>
            <p className="vacation-mode-section-text">Durante el tiempo que se encuentre activado, la gente no podra desafiarte.</p>
            <p className="vacation-mode-section-text">Los días que tengas activado el Modo Vacaciones no contaran como días inactivos, por lo que podras evitar el descenso de jugadores inactivos que ocurre el ultimo día de cada mes.</p>
            <div className="vacation-mode-container">
              <div className='vacation-mode-column'>
                <div className="vacation-mode-container-days-remaining">
                  <h3 className="subheading">Días de vacaciones restantes</h3>
                  <p className="text">{player.remainingVacationDays}</p>
                </div>
                <h3 className="subheading">Modo Vacaciones
                  <span className={`vacation-mode-status-${vacationMode ? 'active' : 'inactive'}`}>{vacationMode ? 'Activado' : 'Desactivado'}</span>
                </h3>
                {vacationMode && (
                  <div className="vacation-info">
                    <h3 className="subheading">Fecha de inicio:
                      <span className={`vacation-mode-status-${vacationMode ? 'active' : 'inactive'}`}>{vacationMode ? vacationModeStartDateFormatted : null}</span>
                    </h3>
                    <h3 className="subheading">Fecha de fin:
                      <span className={`vacation-mode-status-${vacationMode ? 'active' : 'inactive'}`}>{vacationMode ? vacationModeEndDateFormatted : null}</span>
                    </h3>
                    <h3 className="subheading">Días solicitados:
                      <span className={`vacation-mode-status-${vacationMode ? 'active' : 'inactive'}`}>{vacationMode ? player.vacationModeRequestedDays : null}</span>
                    </h3>

                  </div>
                )}
              </div>
              <div className='vacation-mode-column'>
                {vacationMode ? (
                  <button
                    onClick={handleToggleVacationMode}
                    className={`vacation-mode-button deactivate`}>
                    Desactivar modo vacaciones ahora
                  </button>
                ) :
                  <button
                    onClick={() => setVacationModeTabOpen(!vacationModeTabOpen)}
                    className={`vacation-mode-button activate`}>
                    Activar modo vacaciones
                  </button>
                }

                {vacationModeTabOpen && (
                  <div className="vacation-days-input">
                    <label htmlFor="vacation-days">Días (Max {player.remainingVacationDays}):</label>
                    <input
                      type="number"
                      id="vacation-days"
                      value={vacationDays}
                      onChange={handleVacationDaysChange}
                      className="vacation-days-input-field"
                      min="0"
                      max="30"
                      required
                    />
                    <button
                      onClick={handleToggleVacationMode}
                      className="vacation-save-button"
                    >
                      {vacationMode ? 'Desactivar' : 'Activar'}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <h2 className="heading-secondary">Nivel</h2>
      <div className="player-level">
        <div className="ranking-column">
          <h3 className="subheading center">Nivel (0-20)</h3>
          <p className="text center">{player.dynamicLevel.toFixed(2)}</p>
        </div>
        <div className="ranking-column">
          <h3 className="subheading center">Confiabilidad del nivel</h3>
          <p className="text center">{player.rankingReliability !== undefined ? `${(player.rankingReliability * 100).toFixed(0)}%` : '-'}</p>
        </div>
      </div>

      {/* Totals Section */}
      <h2 className="heading-secondary">Totales</h2>
      <div className="player-rankings">
        <div className="ranking-column">
          <h3 className="subheading">Desafíos totales jugados</h3>
          <p className="text-ranking">{playerTotals}</p>
        </div>
      </div>

      {/* Statistics Section */}
      <h2 className="heading-secondary">Últimos 12 meses</h2>
      {playerStats ? (
        <>
          <div className="player-stats">
            {/* Sección de Partidos */}
            {/* Gráfico de Partidos: Victorias vs Derrotas */}
            {
              (playerStats.matchesWon !== 0 || playerStats.matchesLost !== 0) && (
                <div className="stats-subsection">
                  <h3 className="stats-subheading">DESAFÍOS</h3>
                  <div className='pie-chart-container'>
                    <PieChart
                      series={[
                        {
                          data: [
                            {
                              id: 0,
                              value: playerStats.matchesWon,
                              label: `Victorias: ${playerStats.matchesWon} (${playerStats.matchesWon ? ((playerStats.matchesWon / (playerStats.matchesWon + playerStats.matchesLost)) * 100).toFixed(1) : 0}%)` || 'Victorias (0%)',
                              color: 'green'
                            },
                            {
                              id: 1,
                              value: playerStats.matchesLost,
                              label: `Derrotas: ${playerStats.matchesLost} (${playerStats.matchesLost ? ((playerStats.matchesLost / (playerStats.matchesWon + playerStats.matchesLost)) * 100).toFixed(1) : 0}%)` || 'Derrotas (0%)',
                              color: 'red'
                            },
                          ],
                          innerRadius: isMobile ? 30 : 40,
                          outerRadius: isMobile ? 100 : 130,
                          cornerRadius: 5,
                          cx: isMobile ? 120 : 220,
                          cy: isMobile ? 120 : 170,
                          highlightScope: { fade: 'global', highlight: 'item' },
                          faded: { innerRadius: 20, additionalRadius: -20, color: 'gray' },
                        },
                      ]}
                      slotProps={{
                        legend: {
                          direction: 'row',
                          position: { vertical: 'bottom', horizontal: 'middle' },
                          padding: isMobile ? 10 : 20,
                        },
                      }}
                      width={isMobile ? 250 : 500}
                      height={isMobile ? 320 : 380}
                    />
                  </div>

                </div>
              )
            }

            {/* Sección de Juegos */}
            {
              (playerStats.totalGamesWon !== 0 || playerStats.totalGamesLost !== 0) && (
                <div className="stats-subsection">
                  <h3 className="stats-subheading">GAMES</h3>
                  <div className='pie-chart-container'>
                    <PieChart
                      series={[
                        {
                          data: [
                            {
                              id: 0,
                              value: playerStats.totalGamesWon,
                              label: `Ganados: ${playerStats.totalGamesWon} (${playerStats.totalGamesWon ? ((playerStats.totalGamesWon / (playerStats.totalGamesWon + playerStats.totalGamesLost)) * 100).toFixed(1) : 0}%)` || 'Victorias (0%)',
                              color: 'green'
                            },
                            {
                              id: 1,
                              value: playerStats.totalGamesLost,
                              label: `Perdidos: ${playerStats.totalGamesLost} (${playerStats.totalGamesLost ? ((playerStats.totalGamesLost / (playerStats.totalGamesWon + playerStats.totalGamesLost)) * 100).toFixed(1) : 0}%)` || 'Derrotas (0%)',
                              color: 'red'
                            },
                          ],
                          innerRadius: isMobile ? 30 : 40,
                          outerRadius: isMobile ? 100 : 130,
                          cornerRadius: 5,
                          cx: isMobile ? 120 : 220,
                          cy: isMobile ? 120 : 170,
                          highlightScope: { fade: 'global', highlight: 'item' },
                          faded: { innerRadius: 20, additionalRadius: -20, color: 'gray' },
                        },
                      ]}
                      slotProps={{
                        legend: {
                          direction: 'row',
                          position: { vertical: 'bottom', horizontal: 'middle' },
                          padding: isMobile ? 10 : 20,
                        },
                      }}
                      width={isMobile ? 250 : 500}
                      height={isMobile ? 320 : 380}
                    />
                  </div>
                </div>
              )
            }
          </div>

          {/* Sección de Rachas Ganadoras */}
          <h2 className="heading-secondary">Rachas ganadoras</h2>
          <div className="player-rankings">
            <div className="ranking-column">
              <h3 className="subheading">Actual</h3>
              <p className="text-ranking">{playerStats.currentStreak > 0 ? '+' : ''} {playerStats.currentStreak || '0'}</p>
            </div>
            <div className="ranking-column">
              <h3 className="subheading">Mas larga</h3>
              <p className="text-ranking">{playerStats.longestStreak > 0 ? '+' : ''} {playerStats.longestStreak || '0'}</p>
            </div>
          </div>
        </>
      ) : (
        <p>No hay estadísticas disponibles para este año.</p>
      )}

      <h2 className="heading-secondary">Rankings</h2>
      <div className="player-rankings">
        {player.absoluteOrdinalRanking !== null && (
          <div className="ranking-column">
            <h3 className="subheading">Ranking Absoluto</h3>
            <p className="text-ranking">{player.absoluteOrdinalRanking !== null ? player.absoluteOrdinalRanking : '-'}</p>
          </div>
        )}
        {player.age45OrdinalRanking !== null && (
          <div className="ranking-column">
            <h3 className="subheading">Ranking Edad +45</h3>
            <p className="text-ranking">{player.age45OrdinalRanking !== null ? player.age45OrdinalRanking : '-'}</p>
          </div>
        )}
        {player.age55OrdinalRanking !== null && (
          <div className="ranking-column">
            <h3 className="subheading">Ranking Edad +55</h3>
            <p className="text-ranking">{player.age55OrdinalRanking !== null ? player.age55OrdinalRanking : '-'}</p>
          </div>

        )}
      </div>

      <RankingChart />

      <LevelRankingChart />

      {isAuthenticated && playerAuth && playerAuth.id === player.id && shouldRenderLoadPendingChallenges && (
        <>
          <h2 className="heading-secondary">Carga el resultado</h2>
          <div className="player-challenges">
            <ChallengeList status={"accepted"} from={lastYear} to={today} playerId={player.id} setShouldRender={setShouldRenderLoadPendingChallenges} />
          </div>
        </>
      )}
      {shouldRenderMyNextChallenges && (
        <>
          <h2 className="heading-secondary">Próximos Desafíos de {player.firstName}</h2>
          <div className="player-challenges">
            <ChallengeList status={"accepted"} from={today} to={nextYear} playerId={player.id} setShouldRender={setShouldRenderMyNextChallenges} />
          </div>
        </>
      )}
      {isAuthenticated && playerAuth && playerAuth.id === player.id && shouldRenderPendingChallenges && (
        <>
          <h2 className="heading-secondary">Desafíos Pendientes de respuesta</h2>
          <div className="player-challenges">
            <ChallengeList status={"pending"} from={lastYear} to={nextYear} playerId={player.id} setShouldRender={setShouldRenderPendingChallenges} />
          </div>
        </>
      )}
      
      {shouldRenderPlayedChallenges && (
        <>
          <h2 className="heading-secondary">Últimos Resultados de {player.firstName}</h2>
          <div className="player-matches">
            <ChallengeList status={"played"} from={lastYear} to={tomorrow} playerId={player.id} setShouldRender={setShouldRenderPlayedChallenges} />
            <MatchList from={lastYear} to={tomorrow} playerId={player.id} />
          </div>
        </>
      )}
    </div>
  );
};

export default PlayerDetails;
