import { getPlayerById } from "../services/PlayerService";
import { Player } from "../types/Player";

/**
 * @param playerId The player ID
 * @returns True if vacation mode is active, false otherwise.
 */
export const isVacationModeActive = async (player: Player): Promise<boolean> => {
    const vacationPlayer = await getPlayerById(player.id);
    if (!vacationPlayer) return false;

    return vacationPlayer.vacationMode || 
    (   vacationPlayer.vacationModeStartDate !== null && 
        vacationPlayer.vacationModeEndDate !== null && vacationPlayer.vacationModeEndDate !== undefined &&
        vacationPlayer.vacationModeEndDate >= new Date()
    );

}
