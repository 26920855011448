import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOrdinalRankings } from '../../services/RankingService';
import { translateRankingType, translateRankingTypeRaw } from '../../helpers/translateRankingType';
import { RankType } from '../../types/RankType';
import { OrdinalRankingView } from '../../types/BaseRankingView';
import './RankingTable.css';
import { useAuth } from '../../context/AuthContext';
import { getOtherRankTypes } from '../../helpers/rankHelpers';
import { useMediaQuery } from 'react-responsive';
import { isVacationModeActive } from '../../helpers/vacationModeHelper';
import { getPlayerById } from '../../services/PlayerService';

interface RankingTableProps {
  rankType: RankType;
}

const RankingTable: React.FC<RankingTableProps> = ({ rankType }) => {
  const [rankings, setRankings] = useState<OrdinalRankingView[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [loggedInPlayerIsVacationMode, setloggedInPlayerIsVacationMode] = useState<boolean>(false);
  const [initialPage, setInitialPage] = useState<number>(1);

  const { playerAuth } = useAuth();
  const pageSize = 10;
  const otherRankTypes = getOtherRankTypes(rankType);

  const loadRankings = useCallback(async (page: number) => {
    setLoading(true);
    setError(null);

    try {
      if (playerAuth) {
        // Calculate the initial page based on playerAuth or default to the first page
        const playerData = await getPlayerById(playerAuth.id);
        const playerRankValue = playerData[rankType] ?? 1;
        const initialPage = Math.floor((playerRankValue - 1) / pageSize) + 1;
        setInitialPage(initialPage);

        // Fetch rankings for the current page
        const startRank = (page - 1) * pageSize + 1;
        const endRank = page * pageSize;
        const response = await getOrdinalRankings(startRank, endRank, rankType, playerData.id);
        setTotalCount(response.totalCount);
        setTotalPages(Math.ceil(response.totalCount / pageSize));
        setRankings(response.rankings);

        //Vacation Mode
        const vacationModeActive = await isVacationModeActive(playerAuth);
        setloggedInPlayerIsVacationMode(vacationModeActive);
      }
    } catch (err) {
      console.error('Error fetching rankings:', err);
      setError('Error al obtener los rankings.');
    } finally {
      setLoading(false);
    }
  }, [rankType, playerAuth]);

  // Load initial rankings based on initialPage
  useEffect(() => {
    setCurrentPage(initialPage);
    loadRankings(initialPage);
  }, [initialPage, loadRankings]);

  // Handle Previous Page
  const handlePrevious = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      loadRankings(newPage);
    }
  };

  // Handle Next Page
  const handleNext = () => {
    if (currentPage * pageSize < totalCount) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      loadRankings(newPage);
    }
  };

  if (error) return <p>{error}</p>;

  return (
    <div className="ranking-table-container">
      <h2 className="ranking-heading">{translateRankingType(rankType)}</h2>
      <div className="ranking-table-wrapper">
        <table className="ranking-table">
          <thead>
            <tr>
              <th className=''>{translateRankingTypeRaw(rankType)}</th>
              <th className=''>Jugador</th>
              <th className=''><strong>Nivel</strong> <span className='confiabilidad-span-th'>(% Confiabilidad)</span></th>
              <th className='column-centered mobile-hide'>{translateRankingType(otherRankTypes[0])}</th>
              <th className='column-centered mobile-hide'>{translateRankingType(otherRankTypes[1])}</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              // Render empty rows with placeholders while loading
              Array.from({ length: pageSize }).map((_, index) => (
                <tr key={index}>
                  <td className='column-centered'>...</td>
                  <td className='column-centered'>...</td>
                  <td className='column-centered'>...</td>
                  <td className='mobile-hide column-centered'>...</td>
                  <td className='mobile-hide column-centered'>...</td>
                </tr>
              ))
            ) : (
              rankings.map((ranking) => (
                <tr
                  key={ranking.id}
                  className={playerAuth && ranking.id === playerAuth.id ? 'highlight-row' : ''}
                >
                  <td className=''>
                    {ranking.selectedOrdinalRank}
                    <span
                      className={`ranking-change-week ${ranking.selectedOrdinalRankChange < 0
                        ? 'text-green-600'
                        : ranking.selectedOrdinalRankChange > 0
                          ? 'text-red-600'
                          : 'text-gray-600'
                        }`}
                    >
                      {ranking.selectedOrdinalRankChange === 0
                        ? '' // Show '-' if change is zero
                        : `(${ranking.selectedOrdinalRankChange < 0 ? '+' : ''}${-ranking.selectedOrdinalRankChange})`
                      }
                    </span>
                  </td>
                  <td className='player-name-containertd'>
                    <div className="ranking-player-name-box" title={ranking.isChallengeable ? `Desafiar a ${ranking.playerName}` : ''}>
                      <Link to={`/players/${ranking.id}`} style={{ display: 'contents' }}>
                        <div className='player-name-container-row'>
                          {ranking.vacationModeActive && (
                            <div className='leyend-item-image'>
                              <img src="/icons/vacation.png" alt="Vacation Icon" />
                            </div>
                          )}
                          {/* VacationMode off and Inactive player */}
                          {!ranking.vacationModeActive && !ranking.active && (
                            <div className='leyend-item-image'>
                              <img src="/icons/inactive.png" alt="Inactive Icon" />
                            </div>
                          )}

                          <p>{ranking.playerName}</p>
                        </div>
                      </Link>
                      {ranking.isChallengeable && !loggedInPlayerIsVacationMode && (
                        <Link to={`/create-challenge?challengedId=${ranking.id}&rankingType=${rankType}`} style={{ display: 'contents' }}>
                          <div className="challenge-icon">
                            {isMobile ? 'D' : 'Desafiar'}
                          </div>
                        </Link>
                      )}
                    </div>
                  </td>
                  <td className='elo-rating-ordinal-table'>
                    {ranking.dynamicLevel.toFixed(2)} {ranking.rankingReliability !== undefined ? <span>{`(${(ranking.rankingReliability * 100).toFixed(0)}%)`}</span> : 'N/A'}
                  </td>
                  <td className='column-centered mobile-hide'>
                    {
                      otherRankTypes[0] === 'absoluteOrdinalRanking' ? ((ranking.absoluteOrdinalRanking > 0) ? ranking.absoluteOrdinalRanking : '-') :
                        otherRankTypes[0] === 'age45OrdinalRanking' ? ((ranking.age45OrdinalRanking > 0) ? ranking.age45OrdinalRanking : '-') :
                          (ranking.age55OrdinalRanking > 0) ? ranking.age55OrdinalRanking : '-'
                    }
                  </td>
                  <td className='column-centered mobile-hide'>
                    {
                      otherRankTypes[1] === 'absoluteOrdinalRanking' ? ((ranking.absoluteOrdinalRanking > 0) ? ranking.absoluteOrdinalRanking : '-') :
                        otherRankTypes[1] === 'age45OrdinalRanking' ? ((ranking.age45OrdinalRanking > 0) ? ranking.age45OrdinalRanking : '-') :
                          (ranking.age55OrdinalRanking > 0) ? ranking.age55OrdinalRanking : '-'
                    }
                  </td>
                </tr>
              )))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={handlePrevious} disabled={currentPage === 1}>
          Anterior
        </button>
        <span className="pagination-info">
          Página {currentPage} de {totalPages}
        </span>
        <button onClick={handleNext} disabled={currentPage * pageSize >= totalCount}>
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default RankingTable;
