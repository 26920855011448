import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useAuth } from '../../context/AuthContext';

const Header: React.FC = () => {
  const { isAuthenticated, playerAuth, userAuth } = useAuth();
  const toRedirect = isAuthenticated ? '/players/' + playerAuth?.id : '/login';

  return (
    <header className="header-container text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="whats-icon-container">
          <Link to="/" className="whats-icon">
            <img src="/whats-icon-old.png" alt="Whats Icon" />
          </Link>
        </div>
        <nav>
          <ul className="top-menu-bar space-x-4">
            {userAuth?.role === 'admin' && (
              <li className='top-bar-hidden'>
                <Link to="/tennisadmin" className="hover:underline">Admin</Link>
              </li>
            )}
            <li>
              <Link to="/challenges" className="hover:underline">Desafios</Link>
            </li>
            <li >
              <Link to="/rankings" className="hover:underline">Rankings</Link>
            </li>
            {isAuthenticated ? (
              <>
                <li>
                  <Link to={toRedirect} className="hover:underline">{playerAuth ? playerAuth.firstName : 'Mi perfil'}</Link>
                </li>
                <li>
                  <Link to="/logout" className="hover:underline">Logout</Link>
                </li>
              </>
            ) : (
              <li>
                <Link to="/login" className="hover:underline">Login</Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
