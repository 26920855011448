import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { acceptChallenge, rejectChallenge, fetchChallengesByStatus, cancelChallenge } from '../../services/ChallengeService';
import { ChallengeView } from '../../types/ChallengeView';
import './ChallengeList.css';
import { translateChallengeStatus } from '../../helpers/translateChallengeStatus';
import { translateRankingType } from '../../helpers/translateRankingType';
import { useAuth } from '../../context/AuthContext';
import AcceptChallengeButton from '../Buttons/AcceptChallengeButton/AcceptChallengeButton';
import { ChallengeStatus } from '../../types/ChallengeStatus';
import RejectChallengeButton from '../Buttons/RejectChallengeButton/RejectChallengeButton';
import CancelChallengeButton from '../Buttons/CancelChallengeButton/CancelChallengeButton';
import LoadResultButton from '../Buttons/LoadResultButton/LoadResultButton';
import { formatDate, isDateBeforeOrEqualtoToday, isDateBeforeToday } from '../../helpers/timeHelpers';

interface ChallengeListProps {
  status: ChallengeStatus;
  from: string;
  to: string;
  playerId?: number;
  onlyChallenged?: boolean;
  setShouldRender: React.Dispatch<React.SetStateAction<boolean>>;
  orderDirection?: string;
}

const ChallengeList: React.FC<ChallengeListProps> = ({ status, from, to, playerId, onlyChallenged = false, setShouldRender, orderDirection }) => {
  const [challenges, setChallenges] = useState<ChallengeView[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { playerAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadChallenges = async () => {
      try {
        const challengesData = await fetchChallengesByStatus(status, from, to, playerId, orderDirection);
        if (onlyChallenged && playerAuth) {
          setChallenges(challengesData.filter(challenge => challenge.challenged.id === playerAuth.id));
        } else {
          setChallenges(challengesData);
        }
        if (challengesData.length === 0) {
          setShouldRender(false);
        }
      } catch (err) {
        setError('Error al obtener los desafíos');
      } finally {
        setLoading(false);
      }
    };

    loadChallenges();
  }, [status, from, to, playerId, onlyChallenged, playerAuth, setShouldRender, orderDirection]);

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;

  const handleAcceptChallenge = async (challengeId: number) => {
    try {
      await acceptChallenge(challengeId);
      window.location.reload(); // Refresh the page
    } catch (err) {
      console.error('Error al aceptar el desafío');
    }
  };

  const handleRejectChallenge = async (challengeId: number) => {
    try {
      await rejectChallenge(challengeId);
      window.location.reload(); // Refresh the page
    } catch (err) {
      console.error('Error al rechazar el desafío');
    }
  };

  const handleCancelChallenge = async (challengeId: number) => {
    try {
      await cancelChallenge(challengeId);
      window.location.reload(); // Refresh the page
    } catch (err) {
      console.error('Error al cancelar el desafío');
    }
  };

  const handleClickChallenge = (challengeId: number) => {
    navigate('/challenges/' + challengeId);
  }

  return (
    <div className="challenge-grid">
      {challenges.length === 0 ? (
        <p>No se encontraron desafíos.</p>
      ) : (
        challenges.map((challenge) => (
          <div key={challenge.id} className="challenge-box" onClick={() => handleClickChallenge(challenge.id)}>
            {challenge.isTestChallenge && (
              <div className="test-challenge-banner">
                <p>Prueba de Nivel</p>
              </div>
            )}
            {challenge.isFreeChallenge && (
              <div className="free-challenge-banner">
                <p>Desafío Libre</p>
              </div>
            )}
            <div className="challenge-header">
              <p>Desafío <strong> {translateChallengeStatus(challenge.status)}</strong></p>
              <div className="challenge-details">
                <div className="player-box-info">
                  <div className="player-name-box-info">
                    <p>DESAFIANTE</p>
                    <Link
                      to={`/players/${challenge.challenger.id}`}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <strong>{challenge.challenger.firstName} {challenge.challenger.lastName}</strong>
                    </Link>
                  </div>
                  <p>Nivel: <strong>{challenge.challenger.dynamicLevel.toFixed(2)}</strong></p>
                  <p>Ranking: <strong>{challenge.challenger.playerPreMatchRanking}</strong></p>
                </div>
                <div className="player-box-info">
                  <div className="player-name-box-info">
                    <p>DESAFIADO</p>
                    <Link
                      to={`/players/${challenge.challenged.id}`}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <strong>{challenge.challenged.firstName} {challenge.challenged.lastName}</strong>
                    </Link>
                  </div>
                  <p>Nivel: <strong>{challenge.challenged.dynamicLevel.toFixed(2)}</strong></p>
                  <p>Ranking: <strong>{challenge.challenged.playerPreMatchRanking}</strong></p>
                </div>
              </div>
              <div className='challenge-info-container icon-small mt-3'>
                <img src="/icons/podium.png" alt="Whats Icon" />
                <p><strong>{translateRankingType(challenge.rankingType)}</strong></p>
              </div>
              <div className='challenge-info-container icon-small'>
                <img src="/icons/calendar.png" alt="Whats Icon" />
                <p><strong>{formatDate(challenge.proposedDate)}</strong></p>
              </div>
              {/* Show serve info */}
              {challenge.status && (challenge.status === "accepted") && (
                <>
                  <div className='challenge-serve-info-container icon-small'>
                    <img src="/icons/tennis-balls.png" alt="Whats Icon" />
                    <p><strong>{challenge.isPlayer1BringingBalls ? challenge.challenger.firstName + " " + challenge.challenger.lastName : challenge.challenged.firstName + " " + challenge.challenged.lastName} debe poner bolas</strong></p>
                  </div>
                </>
              )}
            </div>

            {/* Show results if available */}
            {challenge.result && (
              <div className="challenge-result">
                {challenge.result.walkover ? (
                  <p><strong>Resultado</strong> W/O</p>
                ) : challenge.result.abandoned ? (
                  <p><strong>Resultado</strong> {challenge.result.sets.map(set => set.score).join(', ')} y abandono</p>
                ) : (
                  <>
                    <p><strong>Resultado</strong> {challenge.result.sets.map(set => set.score).join(', ')}</p>
                  </>
                )}
                <p className='result-player-link-container'>
                  <strong>Ganador</strong> {challenge.winnerId === challenge.challenger.id ? (
                    <Link
                      className='result-player-link'
                      to={`/players/${challenge.challenger.id}`}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <span>{challenge.challenger.firstName} {challenge.challenger.lastName}</span>
                      {/* Icon to show if moved up */}
                      {challenge.challengerMovedUp > 0 && (
                        <>
                          <span>
                            <img className="moved-up-icon" src="/icons/moved-up.png" alt="MovedUp" />
                            <strong className='ranks-moved-up-strong'>{challenge.challengerMovedUp}</strong>
                          </span>
                        </>
                      )}
                    </Link>
                  ) : (
                    <Link
                      className='result-player-link'
                      to={`/players/${challenge.challenged.id}`}
                      onClick={(event) => event.stopPropagation()}
                    >
                      <span>{challenge.challenged.firstName} {challenge.challenged.lastName}</span>
                      {/* Icon to show if moved up */}
                      {challenge.challengedMovedUp > 0 && (
                        <>
                          <span>
                            <img className="moved-up-icon" src="/icons/moved-up.png" alt="MovedUp" />
                            <strong className='ranks-moved-up-strong'>{challenge.challengedMovedUp}</strong>
                          </span>
                        </>
                      )}
                    </Link>
                  )}</p>
              </div>
            )}

            {/* Show if the match is confirmed */}
            {challenge.status === 'played' && (
              <div className="match-confirmation">
                {challenge.isMatchConfirmed ? <img src="/icons/accept.png" alt="Confirmado" /> : <img src="/icons/time.png" alt="ConfirmacionPendiente" />}
                <p>Resultado {challenge.isMatchConfirmed ? 'confirmado' : 'esperando confirmacion'}</p>
              </div>
            )}

            {/* Show accept button if the authenticated player is the challenged player */}
            {playerAuth && playerAuth.id === challenge.challenged.id && status === 'pending' && (
              <div className='accept-reject-challenge-container'>
                <AcceptChallengeButton challengeId={challenge.id} onAccept={handleAcceptChallenge} />
                <RejectChallengeButton challengeId={challenge.id} onReject={handleRejectChallenge} />
              </div>
            )}

            {/* Show Load result button */}
            {playerAuth &&
              (playerAuth.id === challenge.challenged.id || playerAuth.id === challenge.challenger.id) &&
              challenge.status === 'accepted' &&
              isDateBeforeOrEqualtoToday(new Date(challenge.proposedDate)) && (
                <LoadResultButton challengeId={challenge.id} isAuthenticated />
              )}

            {/* Show cancel button if the authenticated player is the challenged/er player and status is Accepted */}
            {playerAuth &&
              (playerAuth.id === challenge.challenged.id || playerAuth.id === challenge.challenger.id) &&
              challenge.status === 'accepted' && (
                <CancelChallengeButton challengeId={challenge.id} onCancel={handleCancelChallenge} />
              )}

            {/* Show cancel button if the authenticated player is the challenger player and the status is Pending */}
            {playerAuth && (playerAuth.id === challenge.challenger.id) && challenge.status === 'pending' &&
              !isDateBeforeToday(new Date(challenge.proposedDate)) && (
                <CancelChallengeButton challengeId={challenge.id} onCancel={handleCancelChallenge} />
              )}
          </div>
        ))
      )}
    </div>
  );
};

export default ChallengeList;
